<template>
  <card :is-loading="isLoading" :page-title="pageTitle" :page-icon="pageIcon">
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-toolbar flat dense class="grey lighten-2">
            Profile Settings
          </v-toolbar>
          <v-card-text>
            <v-form ref="profileForm" v-model="validLogin" lazy-validation v-on:submit.prevent>
              <v-row class="mt-5">
                <v-col cols="12" md="6">
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="text"
                      v-model="uData.name"
                      :rules="requiredFieldRule"
                      label="Full Name"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="text"
                      v-model="uData.skype"
                      label="Skype"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col cols="12" md="6">
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="text"
                      v-model="uData.phone"
                      :rules="phoneRules"
                      label="Phone"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="text"
                      v-model="uData.address"
                      label="Address"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col>
                  <v-textarea
                      outlined
                      name="input-7-4"
                      label="About Me"
                      color="deep-purple accent-4"
                      v-model="uData.about"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <base-outlined-button
                text="Save"
                color="primary"
                icon="mdi-account"
                :loading="isULoading"
                @onButtonClick="profileBtnHandler"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-toolbar flat dense class="grey lighten-2">
            Change Password
          </v-toolbar>
          <v-card-text>
            <v-form ref="changePasswordForm" v-model="validLogin" lazy-validation v-on:submit.prevent>
              <v-row class="mt-5">
                <v-col>
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="password"
                      v-model="cPData.oPassword"
                      :rules="requiredFieldRule"
                      label="Old Password"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col>
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="password"
                      :rules="[rules.required, rules.min]"
                      v-model="cPData.password"
                      label="New Password"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-5">
                <v-col>
                  <v-text-field
                      outlined
                      dense
                      color="deep-purple accent-4"
                      hide-details="auto"
                      type="password"
                      v-model="cPData.cPassword"
                      :rules="passwordUpdateCRules"
                      label="Confirm Password"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <base-outlined-button
                text="Update"
                color="primary"
                icon="mdi-content-save"
                :loading="isULoading"
                @onButtonClick="passwordBtnHandler"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </card>
</template>

<script>
import {mapState} from "vuex";
import Card from "../components/Card";
import Validation from "../components/mixins/Validation";
import BaseOutlinedButton from "../components/buttons/BaseOutlinedButton";

export default {
  name: "Profile",
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Profile | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  mixins: [Validation],
  components: {BaseOutlinedButton, Card},
  data() {
    return {
      isLoading: false,
      pageTitle: "Profile",
      pageIcon: "mdi-account",
      validLogin: true,
      cPData: {
        oPassword: '',
        password: '',
        cPassword: '',
      },
      isULoading: false,
      isPLoading: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    uData() {
      if(this.user) {
        const {name, skype, about, address, phone, country, email} = this.user;
        return {
          name,
          skype,
          about,
          address,
          phone,
          country,
          email
        }
      }

      return {
        name: '',
        skype: '',
        about: '',
        address: '',
        phone: '',
        country: '',
        email: ''
      }
    }
  },
  methods: {
    profileBtnHandler() {
      this.isULoading = true;
      this.$store
          .dispatch("user/updateProfile", {...this.uData})
          .then((response) => {
            const { data } = response;
            this.setSnackBar({ text: data.message, type: "info" });
          })
          .catch((error) => {
            this.setSnackBar({ text: "Data Validation Failed", type: "error" });
          })
          .finally(() => {
            this.$store.dispatch("loadUsers");
            this.isULoading = false;
          });
    },
    passwordBtnHandler() {
      this.isPLoading = true;
      this.$store
          .dispatch("user/updatePassword", {...this.cPData})
          .then((response) => {
            const { data } = response;
            this.setSnackBar({ text: data.message, type: "info" });
          })
          .catch((error) => {
            this.setSnackBar({ text: error.response.data.errors.message[0], type: "error" });
          })
          .finally(() => {
            this.$store.dispatch("user/loadUsers");
            this.isPLoading = false;
            this.$refs.changePasswordForm.reset();
          });
    },
    setSnackBar({ text, type }) {
      this.$store.commit("SET_SNACKBAR", { text, type });
    },
  }
}
</script>

<style scoped>

</style>